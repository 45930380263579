@import url('./vendor/datepicker.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body::-webkit-scrollbar {
    display: none;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  /**
   * I took reference from here https://github.com/niklasvh/html2canvas/issues/2775#issuecomment-1204988157
   * to fix the issue of exporting congrats modal as canvas.
   */
  img {
    @apply inline-block;
  }
}

:root {
  --max-vh: var(--visible-height-webkit, 100vh);
  --scrollbar-secondary: rgb(39 39 42 / 25%);
  --scrollbar-primary: rgb(24 24 27 25%);
  --scrollbar-bg: #efefef;
}

/* Scrollbar styles */

/* Firefox */
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-secondary) var(--scrollbar-primary);
}

/* Chrome, Edge, and Safari */
.scrollbar::-webkit-scrollbar {
  /* Vertical scrollbar */
  width: 8px;

  /* Horizontal scrollbar */
  height: 4px;
  background: var(--scrollbar-bg);
}

.scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbar-primary);
  border-radius: 2px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-secondary);
  border-radius: 4px;
  border: 4px solid var(--scrollbar-primary);
}

body {
  @apply bg-blue-10 sm:bg-gray-500;
}

.react-hot-toast > div > div {
  @apply !w-full !max-w-[400px] mx-auto !inline-flex;
}

.react-hot-toast [role='status'] {
  @apply !mx-0 !block !w-full;
}

.slider-wrapper .swiper-pagination-bullet-active {
  background-color: white !important;
}

/* vouchers */
.voucher-ticket {
  position: relative;
}

.voucher-ticket::before,
.voucher-ticket::after {
  content: '';
  width: 10px;
  height: calc(100% - 5px);
  position: absolute;
  top: 2.5px;
  background-image: radial-gradient(#fff 3px, transparent 0);
  background-size: 10px 10px;
  background-position-x: -12px;
}

.voucher-ticket::before {
  left: -3px;
}

.voucher-ticket::after {
  right: -7px;
}

.content-entry h1 {
  @apply my-4 text-2xl font-semibold;
}

.content-entry h2 {
  @apply my-3 text-xl font-semibold;
}

.content-entry h3 {
  @apply my-3 text-lg font-semibold;
}

.content-entry h4 {
  @apply my-3 text-base font-semibold;
}

.content-entry p {
  @apply mb-4 text-base;
}

.content-entry *:first-child {
  @apply mt-2;
}

.content-entry .c18 {
  @apply font-semibold;
}

.content-entry ol {
  @apply list-decimal list-inside;
}

.content-entry ul {
  @apply list-disc list-inside;
}

.content-entry li {
  @apply mb-4;
}

.content-entry table {
  @apply border border-gray-500;

  border-collapse: collapse;
}

.content-entry th {
  @apply border border-gray-500 p-2.5;
}

.content-entry td {
  @apply border border-gray-500 p-2.5;

  vertical-align: top;
}

.form-radio:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%231B3FE4' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e") !important;
}

.form-radio:checked:hover,
.form-radio:checked:focus {
  border-color: #1b3fe4;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.markdown-reset li > p {
  display: inline;
}
